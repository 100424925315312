(function (global) {
	'use strict';

	define('jquery', [], function () { return global.jQuery; });
	define('viewData', [], function () { return global.viewData; });

})(this);

require(['jquery', 'viewData'], function ($, viewData) {
	'use strict';

	var init = function () {

		$('.customize-button').click(customizeButtonClickHandler);

		$('.template-preview').click(templatePreviewClickHandler);

	};

	var customizeButtonClickHandler = function () {
		var $this = $(this);
		var $form = $this.parents('form');
		$('#BuildTemplateID').val($this.attr('data-id'));
		$form.submit();
	};

	var templatePreviewClickHandler = function () {
		var $this = $(this);
		var $parent = $this.parents('.template-option');
		$parent.find('.customize-button').click();
	};

	init();
});